import * as React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { getCurrentCard, setCreditCardLoading } from "../../../store/actions/Business";
import { updateCard } from "../../../services/business";
import { pushNotification } from "../../../store/actions/Notifications";
import { ERROR, Notification, SUCCESS } from "../../../models/Notification";
import { deleteInsurerOfferByUserAssuranceId, getAssuranceById, getInsurerOfferByUserAssuranceId, getNewInsuranceOffer } from "../../../services/insurer";
import { StationsTable } from "../../../table/station/StationsTable"
import { ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import { setUserAssurance } from "../../../store/actions/Insurance";
import Modal from "../../../components/Modal/Modal";
import NewOfferModal from "./NewOfferModal";
import Button from "../../../components/ButtonGroup/Button";

const InsuranceOfferDetail = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [didFetchDetail, setDidFetchDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [csvLoading, setCsvLoading] = useState(false);
  const { t } = useTranslation(['insurer']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { offerId } = useParams();
  const [insurerOffer, setInsurerOffer] = useState(undefined);

  const insuranceStore = useSelector(store => store.insurance);
  const userAssurance = insuranceStore.userAssurance;

  useEffect(() => {
    if (!didFetchDetail) {
      setDidFetchDetail(true);
      const userAssuranceId = parseInt(offerId);
      getAssuranceById(userAssuranceId).subscribe({
        next: (userAssuranceDetail) => {
          dispatch(setUserAssurance(userAssuranceDetail.assurance));
        }
      });

      getInsurerOfferByUserAssuranceId(userAssuranceId).subscribe({
        next: (insurerOffer) => {
          setInsurerOffer(insurerOffer.offer);
        }
      });
    }
  }, [didFetchDetail])

  const deleteOffer = React.useCallback(() => {
    const userAssuranceId = parseInt(offerId);
    deleteInsurerOfferByUserAssuranceId(userAssuranceId).subscribe({
      next: (response) => {
        navigate('/Insurer');
      }
    });
  }, [offerId])

  const userHaveTicket = userAssurance?.informations.last5YearsTickets0 === "yes";

  const tickets = React.useMemo(() => {
    if (userAssurance) {
      var tmpTickets = [];
      for (let i = 0; i < 100; i++) {
        if (userAssurance?.informations[`explainTicket${i}`]) {
          tmpTickets.push({
            explain: userAssurance?.informations[`explainTicket${i}`],
            year: userAssurance?.informations[`ticketYear${i}`]
          })
        }
      }
      return tmpTickets;
    }
  }, [userAssurance]);

  const userHaveAccident = userAssurance?.informations.last6YearsAccidents0 === "yes";

  const accidents = React.useMemo(() => {
    if (userAssurance) {
      var tmpTickets = [];
      for (let i = 0; i < 100; i++) {
        if (userAssurance?.informations[`explainAccident${i}`]) {
          tmpTickets.push({
            explain: userAssurance?.informations[`explainAccident${i}`],
            claimAmount: userAssurance?.informations[`claimAmount${i}`],
            claimDate: userAssurance?.informations[`claimDate${i}`]
          })
        }
      }
      return tmpTickets;
    }
  }, [userAssurance]);

  const userHaveInsuranceDetailCar= userAssurance?.informations.insuranceDetailCar && userAssurance?.informations.insuranceDetailCar.length > 0;
  const userInsuranceDetailCar = userHaveInsuranceDetailCar && userAssurance?.informations.insuranceDetailCar.split(',');

  const userHaveInsuranceDetailHome= userAssurance?.informations.insuranceDetailHome && userAssurance?.informations.insuranceDetailHome.length > 0;
  const userInsuranceDetailHome = userHaveInsuranceDetailHome && userAssurance?.informations.insuranceDetailHome.split(',');


  console.log(userAssurance);

  return (
    <div className="min-h-full">

      <main className="pb-8">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="sr-only">Insurance offer detail</h1>
          <div className="flex justify-end mb-8">
            {insurerOffer ? <div className="w-full border-b border-gray-200">
              <p className="text-2xl font-bold text-neatcar">Offer sent</p>
              <div className="flex flex-row items-center justify-between mt-4 pb-2">
                <span className="text-base font-medium text-neatcar w-1/2">{parseFloat(insurerOffer.priceAnually / 100).toFixed(2)}$</span>
                <span className="text-base font-medium text-neatcar flex-1">{insurerOffer.createdAt}</span>
                <Button className={'bg-red-600'} onClick={deleteOffer}>
                  Delete offer
                </Button>
              </div>
            </div> :
              <Button onClick={() => { setIsModalOpen(true) }}>
                Send offer
              </Button>}
          </div>
          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
            <div className="grid grid-cols-1 gap-4">
              <section aria-labelledby="section-2-title">
                <h2 className="sr-only" id="section-2-title">Driver information</h2>
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <div className="p-6">
                    <div className="border-b border-gray-200 bg-white pb-3">
                      <h3 className="text-base font-semibold text-gray-900">Driver</h3>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Full name</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.fullname}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Address</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.address}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Addresse since</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.addressDate}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">License number</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.licenseNumber}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Year got license</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.yearGotLicense}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Birthday</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.birthday}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Car license</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.carLicense}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Credit score</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.creditScore}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Is with current insurer since</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.currentInsurer}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Insurance renewal month</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.currentInsurerMonthExpiry}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Is insured since</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.howLongActualAssurance}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Is open to home insurance</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.insuranceHome}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Gender</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.gender}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Week out of state</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.weekOutOfState}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Verification profile</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.verificationProfile}</dd>
                        </div>
                      </dl>
                    </div>

                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">Discount</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.discount}</dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="grid grid-cols-1 gap-4 lg:col-span-2">
              <section aria-labelledby="section-1-title">
                <h2 className="sr-only" id="section-1-title">Car informations</h2>
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <div className="p-6">
                    <div className="border-b border-gray-200 bg-white pb-3 flex flex-row">
                      <div className="w-1/2 border-r px-8">
                        <h3 className="text-base font-semibold text-gray-900">{userAssurance?.userCar.car.make} {userAssurance?.userCar.car.model}</h3>
                        <p>{userAssurance?.userCar.car.year} - {userAssurance?.userCar.color}</p>
                      </div>
                      <div className="w-1/2 bg-white pb-3 px-8">
                        <h3 className="text-base font-semibold text-gray-900">VIN</h3>
                        <p>{userAssurance?.informations.vin}</p>
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                      <div className="overflow-hidden flex-col p-6 space-y-4">
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Current price</h3>
                          <p>{userAssurance?.informations.assuranceAmount}$</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Damage to other</h3>
                          <p>{userAssurance?.informations.damageOther}$</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Risk to other</h3>
                          <p>{userAssurance?.informations.riskOther}$</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Collision</h3>
                          <p>{userAssurance?.informations.colision ? "Yes" : "No"}</p>
                        </div>

                      </div>
                      <div className="overflow-hidden p-6 flex-col space-y-4">

                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Payment schedule</h3>
                          <p>{userAssurance?.informations.assuranceAmount_segmentControllerOption}</p>
                        </div>

                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Hit & run exemption</h3>
                          <p>{userAssurance?.informations.franchiseExemptionHitAndRun ? "Yes" : "No"}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">New replacement</h3>
                          <p>{userAssurance?.informations.newReplacement ? "Yes" : "No"}</p>
                        </div>

                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Accident forgiveness</h3>
                          <p>{userAssurance?.informations.accidentForgiveness ? "Yes" : "No"}</p>
                        </div>
                      </div>
                      <div className="overflow-hidden">
                        <div className="p-6">
                          <img src={userAssurance?.userCar.pictureUrl} alt="carImage" className="w-48 h-48 object-contain" />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 -mt-8">
                      <div className="overflow-hidden flex-col p-6 space-y-4">
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Purchase amount</h3>
                          <p>{userAssurance?.informations.financeAmount}$</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Antitheft</h3>
                          <p>{userAssurance?.informations.antitheft}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Bought state</h3>
                          <p>{userAssurance?.informations.boughtState}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Franchise</h3>
                          <p>{userAssurance?.informations.franchise}$</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Kilometer daily</h3>
                          <p>{userAssurance?.informations.kilometerDaily}KM</p>
                        </div>
                      </div>
                      <div className="overflow-hidden flex-col p-6 space-y-4">
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Interest</h3>
                          <p>{userAssurance?.informations.interest}%</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Current insurer</h3>
                          <p>{userAssurance?.informations.currentInsurer}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Bought type</h3>
                          <p>{userAssurance?.informations.boughtType}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Insurer cancelation number in last 5 years</h3>
                          <p>{userAssurance?.informations.insurerCancelationNumberInLast5Years}</p>
                        </div>
                      </div>
                      <div className="overflow-hidden flex-col p-6 space-y-4">
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Finance duration</h3>
                          <p>{userAssurance?.informations.financeDuration}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Bought date</h3>
                          <p>{userAssurance?.informations.boughtDate}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Civil responsability</h3>
                          <p>{userAssurance?.informations.civilResponsability}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Kilometer annually</h3>
                          <p>{userAssurance?.informations.kilometerAnnually}KM</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">Usage</h3>
                          <p>{userAssurance?.informations.usage}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="overflow-hidden rounded-lg bg-white shadow mt-8">
                  <div className="p-6">
                    {userHaveInsuranceDetailCar && <ul className="w-full space-y-8">
                      <li className="flex flex-row pb-2">
                        <div className="w-1/2">
                          <p className="text-3xl font-bold text-neatcar">
                            Car Details
                          </p>
                        </div>
                      </li>
                      {userInsuranceDetailCar.map((carDetail, index) => (
                        <li key={`ticket-${index}`} className="flex flex-row border-b border-gray-200 pb-2">
                          <div className="w-full">
                            <p>
                              {carDetail}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>}
                  </div>
                </div>
                <div className="overflow-hidden rounded-lg bg-white shadow mt-8">
                  <div className="p-6">
                    {userHaveInsuranceDetailHome && <ul className="w-full space-y-8">
                      <li className="flex flex-row pb-2">
                        <div className="w-1/2">
                          <p className="text-3xl font-bold text-neatcar">
                            Home Details
                          </p>
                        </div>
                      </li>
                      {userInsuranceDetailHome.map((homeDetail, index) => (
                        <li key={`ticket-${index}`} className="flex flex-row border-b border-gray-200 pb-2">
                          <div className="w-full">
                            <p>
                              {homeDetail}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>}
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="flex w-full mt-16 flex-col">
            <div className="flex flex-col w-full">
              <p className="font-bold text-3xl ">Tickets</p>
            </div>
            {userHaveTicket && <ul className="w-full space-y-8 mt-8">
              <li className="flex flex-row border-b border-gray-200 pb-2">
                <div className="w-1/2">
                  <p className="text-lg font-bold text-neatcar">
                    Explication
                  </p>
                </div>
                <div className="w-1/2">
                  <p className="text-lg font-bold text-neatcar">
                    Date
                  </p>
                </div>
              </li>
              {tickets.map((ticket, index) => (
                <li key={`ticket-${index}`} className="flex flex-row border-b border-gray-200 pb-2">
                  <div className="w-1/2">
                    <p>
                      {ticket.explain}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p>
                      {ticket.year}
                    </p>
                  </div>
                </li>
              ))}
            </ul>}
          </div>
          <div className="flex w-full mt-16 flex-col">
            <div className="flex flex-col w-full">
              <p className="font-bold text-3xl ">Accident</p>
            </div>
            {userHaveAccident && <ul className="w-full space-y-8 mt-8">
              <li className="flex flex-row border-b border-gray-200 pb-2">
                <div className="w-1/3">
                  <p className="text-lg font-bold text-neatcar">
                    Explication
                  </p>
                </div>
                <div className="w-1/3">
                  <p className="text-lg font-bold text-neatcar">
                    Amount
                  </p>
                </div>
                <div className="w-1/3">
                  <p className="text-lg font-bold text-neatcar">
                    Date
                  </p>
                </div>
              </li>
              {accidents.map((accident, index) => (
                <li key={`accident-${index}`} className="flex flex-row border-b border-gray-200 pb-2">
                  <div className="w-1/3">
                    <p>
                      {accident.explain}
                    </p>
                  </div>
                  <div className="w-1/3">
                    <p>
                      {accident.claimAmount}$
                    </p>
                  </div>
                  <div className="w-1/3">
                    <p>
                      {accident.claimDate}
                    </p>
                  </div>
                </li>
              ))}
            </ul>}
          </div>
        </div>
        <NewOfferModal userAssuranceId={userAssurance?.id} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </main>
    </div>
  )
};

export default InsuranceOfferDetail;
