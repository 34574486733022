import { useEffect, useState } from "react";
import Select from "../../../components/Form/Select"
import Modal from "../../../components/Modal/Modal"
import Button from "../../../components/ButtonGroup/Button";
import { getBrands, createInsurerOffer, setAssuranceAsResponded } from "../../../services/insurer";
import { useNavigate } from "react-router-dom";

export default function NewOfferModal({isModalOpen, setIsModalOpen, userAssuranceId}) {
    const [form, setForm] = useState({});
    const [brands, setBrands] = useState(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (!brands) {
            getBrands().subscribe({
            next: (brands) => {
              setBrands(brands.brands);
            }
          });
        }
      }, [isModalOpen])

    const disabled = !form.brandId || !form.priceAnually || !form.expirationDate || !form.description || !userAssuranceId;

    const handleCreateInsurerOffer = () => {
        if (disabled) {
            return;
        }

        createInsurerOffer({brandId: form.brandId, priceAnually: parseInt(form.priceAnually) * 100, expirationDate: form.expirationDate, userAssuranceId: userAssuranceId, description: form.description}).subscribe({
            next: (response) => {
                setAssuranceAsResponded(userAssuranceId).subscribe({
                    next: (response) => {
                        navigate('/Insurer');
                        setIsModalOpen(false);
                    }
                });
            }
        });
    }

    return (
        <Modal title="New offer" isOpen={isModalOpen} onClose={() => {setIsModalOpen(false)}}>
            
            <Select onSelect={(value) => setForm({...form, brandId: value.id})} label="Select brand" options={brands} />
            
            <div className="flex flex-col mt-4">
                <span className="text-base font-medium text-gray-900">Price annually</span>
                <input
                    onChange={(e) => setForm({...form, priceAnually: e.target.value})}
                    type="number"
                    name="priceAnually"
                    id="priceAnually"
                    className="shadow-sm focus:ring-neatcar focus:border-neatcar block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="2000"
                />
            </div>

            <div className="flex flex-col mt-4">
                <span className="text-base font-medium text-gray-900">Expiration date</span>
                <input
                    onChange={(e) => setForm({...form, expirationDate: e.target.value})}
                    type="date"
                    name="expirationDate"
                    id="expirationDate"
                    className="shadow-sm focus:ring-neatcar focus:border-neatcar block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="2025-01-01"
                />
            </div>

            <div className="flex flex-col mt-4">
                <span className="text-base font-medium text-gray-900">Description</span>
                <textarea
                    onChange={(e) => setForm({...form, description: e.target.value})}
                    type="text"
                    name="description"
                    id="description"
                    className="shadow-sm focus:ring-neatcar focus:border-neatcar block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Description"
                />
            </div>

            <div className="flex justify-end mt-4">
                <Button disabled={disabled} onClick={handleCreateInsurerOffer}>
                    Send offer
                </Button>
            </div>
      </Modal>
    )
}       