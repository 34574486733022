import { useEffect, useState } from "react";
import Paginator from "../Paginator";
import Search from "../Search/Search";

export function useList(data, headers, pageStart, pageSizeStart, sortHeaderStart, sortDirectionStart) {

    const [listData, setListData] = useState({
        page: pageStart,
        pageSize: pageSizeStart,
        sortHeader: sortHeaderStart,
        sortDirection: sortDirectionStart,
        sortedData: data,
        headers: headers,
        search: '',
        totalDataCount: data.length
    });

    useEffect(() => {
        const searchInObject = (obj, searchTerm) => {
            // Handle arrays
            if (Array.isArray(obj)) {
                return obj.some(item => searchInObject(item, searchTerm));
            }
            
            // Handle objects
            if (obj !== null && typeof obj === 'object') {
                return Object.values(obj).some(value => searchInObject(value, searchTerm));
            }
            
            // Handle primitive values
            return String(obj).toLowerCase().includes(searchTerm);
        };

        const filteredData = data.filter((item) => {
            if (listData.search && listData.search.length > 0) {
                const searchTerm = listData.search.toLowerCase();
                return searchInObject(item, searchTerm);
            }
            return true;
        });

        const start = Math.max(0,Math.min(filteredData.length - listData.pageSize, (listData.page - 1) * listData.pageSize));
        const end = Math.min(filteredData.length, start + listData.pageSize);
        const sortedData = filteredData.slice(start, end);
        setListData({
            ...listData,
            data,
            sortedData: sortedData,
            totalDataCount: filteredData.length
        });
    }, [data, listData.page, listData.pageSize, listData.search, sortHeaderStart, sortDirectionStart]);

    return { listData, setListData };
}

export default function List({ listData, setListData, onClick }) {

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="flow-root">
                <Search search={listData.search} setSearch={(value) => setListData({ ...listData, search: value })} />
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-4">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    {listData.headers.map((header) => (
                                        <th key={header.id} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">{header.label}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {listData.sortedData.map((item) => (
                                    <tr onClick={() => { onClick(item) }} key={`${item.id}`} className="hover:bg-gray-50 cursor-pointer">
                                        {listData.headers.map((header) => (
                                            <td key={`${header.id}-${item.id}`} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{header.render(item)}</td>
                                        ))}

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Paginator currentPage={listData.page} itemsPerPage={listData.pageSize} totalItems={listData.totalDataCount} newPage={(page) => setListData({...listData, page: page})} />
                    </div>
                </div>
            </div>
        </div>

    )
}