import { SELECT_USER_ASSURANCE } from '../types/Insurance';
import { LOGOUT } from "../types/Session";

const initialState = {
  userAssurance: undefined,
  insurerOffer: undefined,
};

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case SELECT_USER_ASSURANCE:
      return {
        ...state,
        userAssurance: action.payload,
      };
    
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
