const fr = {
  login: {
    title: 'Compte entreprise',
    subtitle: 'Pas de compte entreprise ?',
    salesLinkLabel: 'Contactez nous.',
    salesLink: 'https://neatcar.com/contact.php',
    email: 'Courriel',
    password: 'Mot de passe',
    rememberMe: 'Se souvenir de moi',
    forgotPassword: 'Mot de passe oublié ?',
    signIn: 'Se connecter',
    wrongCredentials: 'Les identifiants ne sont pas valides',
    required: 'Ce champ est requis',
    wrongEmail: 'Ce courriel n\'est pas valide',
  },
  notFound: {
    error: 'Erreur 404',
    lost: 'Uh oh! Vous avez l\'air perdu.',
    subtitle: 'Il semble que la page que vous recherchez n\'existe pas.',
    goBackHome: 'Revenir à l\'accueil',
  },
  menu: {
    fleat: 'Flotte & Équipe',
    team: 'Équipe',
    billing: 'Facturation',
    otherLanguage: 'English',
    otherLanguageKey: 'en',
    logout: 'Déconnexion',
    marketplace: 'Marketplace',
    marketplaceGiftTitle: 'Cadeau et expérience',
    marketplaceGiftDesc: 'Offrez l\'abonnement Neatcar ou des lavages en cadeau à des clients tout en personnalisant l\'expérience.',
    marketplaceDealershipTitle: 'Concessionnaire',
    marketplaceDealershipDesc: 'Module complet pour personnaliser l\'expérience des concessionnaires automobiles.',
    marketplaceStationTitle: 'Station de lavage',
    marketplaceStationDesc: 'Ajouter votre station de lavage dans Neatcar et rejoingez des milliers d\'automobilistes facilement.',
    marketplaceAdCampaignTitle: 'Campagnes publicitaires',
    marketplaceAdCampaignDesc: 'Les automobilistes sont votre clientèle ? Rejoignez-les au bon moment.',
    marketplaceSalesTitle: 'Souhaitez-vous parler aux ventes ?',
    marketplaceSalesDesc: 'Écrivez-nous : ventes@neatcar.com'
  },
  teams: {
    addMember: 'Ajouter un membre',
    email: 'Courriel',
    or: 'Ou',
    userId: 'User ID',
    userIdHelp: 'Le user ID est affiché dans la page profil de l\'application mobile.',
    addToTeam: 'Ajouter à l\'équipe',
    cancel: 'Annuler',
    empty: 'Vous devez saisir un courriel ou un user ID',
    errors: {
      11: 'Assurez-vous d’entrer un courriel ou ID valide.',
      25: 'Cet utilisateur ne peut pas être ajouté car il est déjà dans votre équipe.',
      26: 'Vous avez atteint le montant maximum d\'employés pour votre plan. Contactez-nous à ventes@neatcar.com si vous souhaitez mettre à jour votre forfait.'
    },
    addSuccessTitle: '{{firstName}} a bien été ajouté',
    addSuccessMessage: '{{firstName}} pourra maintenant acheter des codes de lavage selon ses permissions.'
  },
  member: {
    delete: 'Supprimer',
    deleteTitle: 'Retirer ce compte',
    deleteText: 'Voulez-vous vraiment supprimer ce compte ? Toutes les données seront définitivement supprimées de nos serveurs pour toujours. Cette action ne peut pas être annulée.',
    cancel: 'Annuler',
    maxCarwashPerMonth: 'Nombre limite de lavages par mois',
    startHour: 'Heure minimale',
    endHour: 'Heure maximale',
    days: 'Journées',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',
    update: 'Mettre à jour',
    permissions: 'Permissions'
  },
  transaction: {
    transactions: 'Transactions',
    price: '{{price}}$',
    paidByCard: 'Payé par carte **** {{digits}}',
    download: 'Télécharger',
    export: 'Exporter CSV',
    noTransactions: 'Aucune transaction'
  },
  billing: {
    paymentMethod: 'Méthode de paiement',
    update: 'Mettre à jour',
    cardNumber: 'Numéro de carte',
    name: 'Nom sur la carte',
    expirationDate: 'Date d\'expiration (MM/AA)',
    cvv: 'CVV',
    currentCard: 'Carte de crédit actuelle',
    updateCard: 'Mettre à jour la carte',
    required: 'Champ obligatoire',
    invalid: 'Champ invalide',
    modifiedCreditCardSuccessTitle: 'Carte de crédit mise à jour',
    modifiedCreditCardSuccessMessage: 'Votre carte de crédit a bien été mise à jour.',
    modifiedCreditCardErrorTitle: 'Erreur de mise à jour',
    modifiedCreditCardErrorMessage: 'Votre carte de crédit n\'a pas pu être mise à jour.'
  },
  insurer: {
    newInsurance: 'Nouvelle assurance',
  },
  logout: {
    loggingOut: 'Please wait...',
    loggingOutMessage: 'We are logging you out, you\'ll be automatically redirected'
  },
  pagination: {
    previous: 'Précédents',
    next: 'Suivants',
    showing: 'Résultats ',
    to: ' à ',
    of: ' sur ',
    results: ''
  }
};
export default fr;
