import { Fragment, useEffect, useRef, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import {
  CursorClickIcon,
  MenuIcon,
  LocationMarkerIcon,
  GiftIcon,
  ClipboardListIcon,
  XIcon,
} from '@heroicons/react/outline'
import { useTranslation } from "react-i18next";
import { useLocation, Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import useClickOutside from "../../hooks/UseClickOutside";
import { useSelector } from "react-redux";
import neatcarLogo from "../../assets/images/neatcar-logo.png";
import neatcarSmallLogo from "../../assets/images/neatcar.png";
import EmployeeHeader from "./EmployeeHeader";
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const navigation = [
  {
    key: 'fleat',
    location: '/team',
    roleNeeded: 'ROLE_BUSINESS',
    subMenu: [
      { key: 'team', location: '/team' },
      { key: 'member', location: '/team/member', hidden: true, component: <EmployeeHeader /> },
      { key: 'billing', location: '/team/billing' }
    ]
  },
  {
    key: 'Stations',
    location: '/Stations',
    roleNeeded: 'ROLE_ADMIN',
    subMenu: [
      { key: 'Stations', location: '/Stations' },
      { key: 'Routes', location: '/Stations/RoutesPage' }
    ]
  },
  {
    key: 'Insurer',
    location: '/Insurer',
    roleNeeded: 'ROLE_ASSURANCE', 
    subMenu: [
      { key: 'New', location: '/Insurer' },
      { key: 'Bid Pending user approval', location: '/Insurer/BidPendingUserApproval' },
      { key: 'Bid Approved by user', location: '/Insurer/BidPending' },
      { key: 'Discarded', location: '/Insurer/DiscardedUserAssurance' },
    ]
  }
]

const marketplace = [
  {
    name: 'marketplaceGiftTitle',
    description: 'marketplaceGiftDesc',
    href: '#',
    icon: GiftIcon,
  },
  {
    name: 'marketplaceDealershipTitle',
    description: 'marketplaceDealershipDesc',
    href: '#',
    icon: ClipboardListIcon,
  },
  {
    name: 'marketplaceStationTitle',
    description: 'marketplaceStationDesc',
    href: '#',
    icon: LocationMarkerIcon,
  },
  {
    name: 'marketplaceAdCampaignTitle',
    description: 'marketplaceAdCampaignDesc',
    href: '#',
    icon: CursorClickIcon,
  }
]

const Header = () => {
  const userMenuRef = useRef(null);
  const userMenuButtonRef = useRef(null);
  useClickOutside([userMenuRef, userMenuButtonRef], () => setUserMenuOpen(false));

  const [currentPage, setCurrentPage] = useState(navigation[0]);
  const [currentSubPage, setCurrentSubPage] = useState(navigation[0][0]);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const { t, i18n } = useTranslation('menu');
  const location = useLocation();

  const { user, roles } = useSelector(store => store.session);

  const toggleLanguage = (langKey) => {
    i18n.changeLanguage(langKey);
    dayjs.locale(langKey);
  }

  const toggleUserMenu = (e) => {
    e.preventDefault();
    setUserMenuOpen(!userMenuOpen);
  }

  useEffect(() => {
    const _currentPage = navigation.find(n => location.pathname.startsWith(n.location));
    setCurrentPage(_currentPage);
    setCurrentSubPage(_currentPage.subMenu.find(n => location.pathname === (n.location)))
  }, [location])

  useEffect(() => {
  }, [roles])
  return (
    <>
      <Helmet>
        <title>{`${t(currentPage.key)} | Neatcar`}</title>
      </Helmet>
      <div className="bg-neatcar pb-32">
        <div className="bg-neatcar border-b border-indigo-300 border-opacity-25 lg:border-none">
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
              <div
                className="relative h-16 flex items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                <div className="px-2 flex items-center lg:px-0">
                  <div className="flex-shrink-0">
                    <img
                      className="block h-8"
                      src={neatcarLogo}
                      alt="Workflow"
                    />
                  </div>
                  <div className="hidden lg:block lg:ml-10">
                    <div className="flex space-x-4">
                      {navigation.map(n => (
                        <Link key={n.key}
                          to={n.location}
                          hidden={
                            n.roleNeeded && !roles.find(role => role.role === n.roleNeeded)
                          }
                          className={classNames(
                            location.pathname.startsWith(n.location)
                              ? 'bg-white bg-opacity-20'
                              : 'hover:bg-white',
                            'text-white text-sm font-medium rounded-md px-3 py-2 hover:bg-opacity-10'
                          )
                          }
                          aria-current={location.pathname.startsWith(n.location) ? 'page' : undefined}
                        >
                          {t(n.key)}
                        </Link>
                      )
                      )}
                      {/* <Popover className="relative">
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={classNames(
                                open ? 'bg-white bg-opacity-20'
                                  : 'hover:bg-white',
                                'text-white text-sm font-medium rounded-md px-3 py-2 hover:bg-opacity-10'
                              )}
                            >
                              <span>{t('marketplace')}</span>
                            </Popover.Button>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel
                                className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                  <div
                                    className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                                    {marketplace.map((solution) => (
                                      <a
                                        key={solution.name}
                                        href={solution.href}
                                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                      >
                                        <div
                                          className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-neatcar text-white sm:h-12 sm:w-12">
                                          <solution.icon className="h-6 w-6" aria-hidden="true" />
                                        </div>
                                        <div className="ml-4">
                                          <p className="text-base font-medium text-gray-900">{t(solution.name)}</p>
                                          <p className="mt-1 text-sm text-gray-500">{t(solution.description)}</p>
                                        </div>
                                      </a>
                                    ))}
                                  </div>
                                  <div className="p-5 bg-gray-50 sm:p-8">
                                    <button className="-m-3 p-3 flow-root rounded-md hover:bg-gray-100">
                                      <div className="flex items-center">
                                        <div className="text-base font-medium text-gray-900">{t('marketplaceSalesTitle')}</div>
                                      </div>
                                      <p className="mt-1 text-sm text-gray-500">
                                        {t('marketplaceSalesDesc')}
                                      </p>
                                    </button>
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover> */}
                    </div>
                  </div>
                </div>
                <div className="flex lg:hidden">
                  {/* Mobile menu button */}
                  <button
                    onClick={(e) => toggleUserMenu(e)}
                    className="bg-neatcar p-2 rounded-md inline-flex items-center justify-center text-indigo-200 hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-neatcar focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {userMenuOpen ? <XIcon className="block h-6 w-6" aria-hidden="true" /> :
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />}
                  </button>
                </div>
                <div className="hidden lg:block lg:ml-4">
                  <div className="flex items-center">
                    <span className="text-white text-sm font-medium">{`${user.firstName} ${user.lastName}`}</span>
                    <div className="ml-3 relative flex-shrink-0">
                      <div>
                        <button type="button"
                          ref={userMenuButtonRef}
                          onClick={(e) => toggleUserMenu(e)}
                          className="rounded-full flex text-sm text-white focus:outline-none "
                          id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                          <span className="sr-only">Open user menu</span>
                          <div className="rounded-full h-8 w-8">
                            <img src={neatcarSmallLogo} alt="user logo" />
                          </div>
                        </button>
                      </div>

                      {userMenuOpen && (<div ref={userMenuRef}
                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button"
                        tabIndex="-1">
                        <button
                          key={'language'}
                          onClick={() => toggleLanguage(t('otherLanguageKey'))}
                          className="block py-2 px-4 text-sm text-gray-700"
                        >
                          {t('otherLanguage')}
                        </button>
                        <Link
                          key={'logout'}
                          to={'/logout'}
                          className="block py-2 px-4 text-sm text-gray-700"
                        >
                          {t('logout')}
                        </Link>
                      </div>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Mobile menu content */}
            {userMenuOpen && (<div className="lg:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map(n => (
                  <Link key={n.key}
                    to={n.location}
                    className={classNames(
                      location.pathname.startsWith(n.location)
                        ? 'bg-opacity-20'
                        : '',
                      'block text-white text-base font-medium rounded-md bg-white px-3 py-2 hover:bg-opacity-10'
                    )}
                    aria-current={location.pathname.startsWith(n.location) ? 'page' : undefined}
                  >
                    {t(n.key)}
                  </Link>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-neatcar">
                <div className="px-5 flex items-center">
                  <div className="flex-shrink-0">
                    <div className="rounded-full h-10 w-10 focus:outline-none">
                      <img src={neatcarSmallLogo} alt="user logo" />
                    </div>
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">{`${user.firstName} ${user.lastName}`}</div>
                    <div className="text-sm font-medium text-indigo-300"></div>
                  </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  <button
                    key={'language'}
                    onClick={() => toggleLanguage(t('otherLanguageKey'))}
                    className="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-slate-500 hover:bg-opacity-75"
                  >
                    {t('otherLanguage')}
                  </button>
                </div>
              </div>
            </div>)}
          </>
          
        </div>

        <header className="pt-4 pb-6" style={{ height: '120px' }}>
          {currentSubPage?.component ? currentSubPage.component : ''}
          {!currentSubPage?.component && (<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-white">{t(currentPage.key)}</h1>
            {currentPage.subMenu?.filter(m => !m.hidden).length > 0 && (<nav className="flex space-x-4 mt-2">
              {currentPage.subMenu.filter(m => !m.hidden).map(m => (
                <Link to={m.location}
                  key={m.key}
                  className={classNames(
                    location.pathname === m.location
                      ? 'bg-opacity-20'
                      : 'bg-opacity-0',
                    'text-white text-sm font-medium rounded-md bg-white px-3 py-2 hover:bg-opacity-10'
                  )}
                  aria-current="page">{t(m.key)}</Link>
              ))}
            </nav>)}
          </div>)}
        </header>
      </div>
    </>
  );
};

export default Header;
