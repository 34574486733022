import { useRef, useState } from "react";

export default function Search({ search, setSearch }) {

    const [internalSearch, setInternalSearch] = useState(search);

    const timeoutRef = useRef(null);

    const debouncedSearch = (e) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            setSearch(e.target.value);
        }, 300);

        setInternalSearch(e.target.value);
    }

    return (
        <div>
            <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">Search</label>
            <div className="mt-2">
                <input value={internalSearch} onChange={debouncedSearch} type="search" name="search" id="search" className="block w-full max-w-xs rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-sky-800 sm:text-sm/6" placeholder="Montreal" />
            </div>
        </div>
    )
}