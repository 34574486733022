import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import SecuredLayout from "./components/layout/SecuredLayout";
import BaseLayout from "./components/layout/BaseLayout";
import NotFound from "./pages/404/NotFound";
import Login from "./pages/login/Login";
import Team from "./pages/team/Team";
import Stations from "./pages/stores/Stations";
import RoutesPage from "./pages/stores/RoutesPage";
import Logout from "./pages/logout/Logout";
import { useDispatch } from "react-redux";
import { getUser } from "./store/actions/User";
import Notification from "./components/Notification";
import Member from "./pages/team/Member";
import Billing from "./pages/team/Billing";
import NewInsurance from "./pages/insurer/NewInsuranceOffer/NewInsurance";
import BidPending from "./pages/insurer/BidPending/BidPending";
import InsuranceOfferDetail from "./pages/insurer/InsuranceOfferDetail/InsuranceOfferDetail";
import BidPendingUserApproval from "./pages/insurer/BidPendingUserApproval/BidPendingUserApproval";
import DiscardedUserAssurance from "./pages/insurer/DiscardedUserAssurance/DiscardedUserAssurance";

const App = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch the user in case they are already logged
    dispatch(getUser());
  }, [dispatch]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<BaseLayout/>}>
          <Route path={"/"} element={<Login/>}/>
          <Route path={"/login"} element={<Login/>}/>
        </Route>

        <Route path="/" element={<SecuredLayout/>}>
          <Route path="team" element={<Team/>}/>
        </Route>

        <Route path="/team" element={<SecuredLayout layout={2}/>}>
          <Route path="member" element={<Member/>}/>
          <Route path="billing" element={<Billing/>}/>
        </Route>

        <Route path="/" element={<SecuredLayout/>}>
          <Route path="Stations" element={<Stations/>}/>
          <Route path="Insurer" element={<NewInsurance/>}/>
          <Route path="Insurer/Offer/:offerId" element={<InsuranceOfferDetail/>}/>
        </Route>

        <Route path="/Stations" element={<SecuredLayout/>}>
          <Route path="Stations" element={<Stations/>}/>
          <Route path="RoutesPage" element={<RoutesPage/>}/>
        </Route>

        <Route path="/Insurer" element={<SecuredLayout/>}>
          <Route path="Insurer" element={<NewInsurance/>}/>
          <Route path="BidPendingUserApproval" element={<BidPendingUserApproval/>}/>
          <Route path="BidPending" element={<BidPending/>}/>
          <Route path="DiscardedUserAssurance" element={<DiscardedUserAssurance/>}/>
        </Route>

        <Route path="/logout" element={<Logout/>}/>

        <Route path="*" element={<NotFound/>}/>

      </Routes>
      <Notification />
    </div>
  );
}

export default App;
